/* form {
    margin-top: 20px;
    background-color: #f0f0f0;
    padding: 20px;
    border-radius: 5px;
} */
form label {
    display: block;
    margin-bottom: 10px;
}
form input, form textarea {
    width: 90%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}
form button {
    padding: 10px 20px;
    background-color: #d1410c;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}